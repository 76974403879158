import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardFooter,
	Pagination,
	PaginationItem,
	PaginationLink,
	Table,
	Container,
	Row,
	Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
class Batches extends React.Component {

	batches = [
		{
			id:"000111",
			name: "EMT-SEPT-20",
			start_date: "02 Sep 2020",
			end_date: "05 Oct 2020",
			users: 23
		},
		{
			id:"000111",
			name: "EMT-SEPT-20",
			start_date: "02 Sep 2020",
			end_date: "05 Oct 2020",
			users: 23
		},
		{
			id:"000111",
			name: "EMT-SEPT-20",
			start_date: "02 Sep 2020",
			end_date: "05 Oct 2020",
			users: 23
		},
		{
			id:"000111",
			name: "EMT-SEPT-20",
			start_date: "02 Sep 2020",
			end_date: "05 Oct 2020",
			users: 23
		},
		{
			id:"000111",
			name: "EMT-SEPT-20",
			start_date: "02 Sep 2020",
			end_date: "05 Oct 2020",
			users: 23
		},
	]

	createRows = rows => {
		return rows.map((prop, key) => {
			return (
				<tr>
					<th scope="row">
							<span className="mb-0 text-sm">
								{prop.name}
							</span>
					</th>
					<td>{prop.start_date}</td>
					<td>{prop.end_date}</td>
					<td>{prop.users}</td>
					<td className="text-right">
						<Button color="link" to={`/admin/batch/${prop.id}`} tag={Link}>
							Open
              </Button>
					</td>
				</tr>

			);
		});
	};
	render() {
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt-5" fluid>
					{/* Table */}
					<Row>
						<div className="col">
							<Card className="shadow">
								<CardHeader className="border-0 d-flex justify-content-between">
									<h3 className="mb-0">Batches</h3>
									<Button color="primary" size="md" to="/admin/add-batch" tag={Link}>
										<i className=" ni ni-fat-add" /> Create new Batch
                  </Button>
								</CardHeader>
								<Table className="align-items-center table-flush" responsive>
									<thead className="thead-light">
										<tr>
											<th scope="col">Batch</th>
											<th scope="col">Start Date</th>
											<th scope="col">End Date</th>
											<th scope="col">Users</th>
											<th scope="col" />
										</tr>
									</thead>
									<tbody>{this.createRows(this.batches)}</tbody>
								</Table>
								<CardFooter className="py-4">
									<nav aria-label="...">
										<Pagination
											className="pagination justify-content-end mb-0"
											listClassName="justify-content-end mb-0"
										>
											<PaginationItem className="disabled">
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
													tabIndex="-1"
												>
													<i className="fas fa-angle-left" />
													<span className="sr-only">Previous</span>
												</PaginationLink>
											</PaginationItem>
											<PaginationItem className="active">
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
												>
													1
                        </PaginationLink>
											</PaginationItem>
											<PaginationItem>
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
												>
													2 <span className="sr-only">(current)</span>
												</PaginationLink>
											</PaginationItem>
											<PaginationItem>
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
												>
													3
                        </PaginationLink>
											</PaginationItem>
											<PaginationItem>
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
												>
													<i className="fas fa-angle-right" />
													<span className="sr-only">Next</span>
												</PaginationLink>
											</PaginationItem>
										</Pagination>
									</nav>
								</CardFooter>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

export default Batches;

import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";

class AddUser extends React.Component {
	render() {
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt-3" fluid>
					<Row>
						<Col className="order-xl-1" xl="8">
							<Button color="primary" to="/admin/users" tag={Link} className="mb-2">
								All Users
                </Button>
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="12">
											<h3 className="mb-0">Create New User</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<FormGroup>
												<label
													className="form-control-label"
													htmlFor="input-name"
												>
													Name
                            </label>
												<Input
													className="form-control-alternative"
													id="input-name"
													name="name"
													placeholder="John Doe"
													type="text"
												/>
											</FormGroup>
											<FormGroup>
												<label
													className="form-control-label"
													htmlFor="input-email"
												>
													Email
                            </label>
												<Input
													className="form-control-alternative"
													id="input-email"
													name="email"
													placeholder="name@example.com"
													type="email"
												/>
											</FormGroup>
											<FormGroup>
												<label
													className="form-control-label"
													htmlFor="input-phone"
												>
													Phone
                            </label>
												<Input
													className="form-control-alternative"
													defaultValue={new Date().getDate}
													id="input-phone"
													name="phone"
													placeholder="9876543210"
													type="tel"
												/>
											</FormGroup>
											<FormGroup>
												<label className="form-control-label"
													htmlFor="input-batch">Batch</label>
												<Input type="select" name="batch" id="input-batch">
													<option>select</option>
													<option>1</option>
													<option>2</option>
													<option>3</option>
													<option>4</option>
													<option>5</option>
												</Input>
											</FormGroup>
											<FormGroup>
												<label
													className="form-control-label"
													htmlFor="input-password"
												>
													password
                            </label>
												<Input
													className="form-control-alternative"
													defaultValue={new Date().getDate}
													id="input-password"
													name="password"
													placeholder=""
													type="password"
												/>
											</FormGroup>
											<div className="d-flex justify-content-between">
												<Button color="primary"
													onClick={() => { }}
												>
													Submit
                          </Button>

													<Button
													to="/admin/users"
													tag={Link}
												>
													cancel
                          </Button>
											</div>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default AddUser;

import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";

class AddBatch extends React.Component {
  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt-3" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Button color="primary" to="/admin/batches" tag={Link} className="mb-2">
                All Batches
                </Button>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Create New Batch</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-name"
                        >
                          Name
                            </label>
                        <Input
                          className="form-control-alternative"
                          id="input-name"
                          name="name"
                          placeholder="EMT-Aug-20"
                          type="text"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-start-date"
                        >
                          Start Date
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={new Date().getDate}
                          id="input-start-date"
                          name="start_date"
                          placeholder=""
                          type="date"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-end-date"
                        >
                          End Date
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={new Date().getDate}
                          id="input-end-date"
                          name="end_date"
                          placeholder=""
                          type="date"
                        />
                      </FormGroup>
                      <div className="d-flex justify-content-between">
                        <Button color="primary"
                          onClick={() => { }}
                        >
                          Submit
                          </Button>

                        <Button
                          to="/admin/batches"
                          tag={Link}
                        >
                          cancel
                          </Button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddBatch;

import Index from "views/Index.js";
// import Maps from "views/Maps.js";
// import Register from "views/Register.js";
// import Login from "views/Login.js";
// import Tables from "views/Tables.js";
import Icons from "views/Icons.js";
import Batches from "views/batches";
import AddBatch from "views/AddBatch";
import Users from "views/Users"
import AddUser from "views/AddUser";
import UserDetails from "views/UserDetails"
import Employees from "views/Employees";
import Profile from "views/Profile";
import AddEmployee from "views/AddEmployee";
import BatchDetails from "views/BatchDetails";
import Payments from "views/Payments";
var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-default",
    component: Index,
    layout: "/admin",
    scope:"main"
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "ni ni-money-coins text-default",
    component: Payments,
    layout: "/admin",
    scope:"main"
  },
  {
    path: "/batches",
    name: "Batches",
    icon: "fa fa-users text-default",
    component: Batches,
    layout: "/admin",
    scope:"main"
  },
  {
    path: "/batch/:id",
    name: "Batch Details",
    icon: "fa fa-users text-default",
    component: BatchDetails,
    layout: "/admin"
  },
  {
    path: "/add-batch",
    name: "Add Batch",
    icon: "fa fa-users text-default",
    component: AddBatch,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-default",
    component: Users,
    layout: "/admin",
    scope:"main"
  },
  {
    path: "/add-user",
    name: "Add User",
    icon: "ni ni-single-02 text-default",
    component: AddUser,
    layout: "/admin"
  },
  {
    path: "/user/:id",
    name: "User Details",
    icon: "ni ni-single-02 text-default",
    component: UserDetails,
    layout: "/admin"
  },
  {
    path: "/employees",
    name: "Employees",
    icon: "ni ni-circle-08 text-default",
    component: Employees,
    layout: "/admin",
    scope:"main"
  },
  {
    path: "/add-employee",
    name: "Add Employees",
    icon: "ni ni-circle-08 text-default",
    component: AddEmployee,
    layout: "/admin"
  },
  {
    path: "/employee/:id",
    name: "Employees details",
    icon: "ni ni-circle-08 text-default",
    component: Profile,
    layout: "/admin"
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }
];
export default routes;

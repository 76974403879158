import React from "react";

// reactstrap components
import {
	Badge,
	Card,
	CardHeader,
	CardFooter,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	Pagination,
	PaginationItem,
	PaginationLink,
	Progress,
	Table,
	Container,
	Row,
	UncontrolledTooltip,
	Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
class Users extends React.Component {

	batches = [
		{
			id: "000111",
			active: true,
			name: "ABC",
			email: "ranjeetgupta156@gmail.com",
			phone: "9113076325",

		},
		{
			id: "000111",
			active: true,
			name: "ABC",
			email: "ranjeetgupta156@gmail.com",
			phone: "9113076325",

		},
		{
			id: "000111",
			active: true,
			name: "ABC",
			email: "ranjeetgupta156@gmail.com",
			phone: "9113076325",

		},
		{
			id: "000111",
			active: true,
			name: "ABC",
			email: "ranjeetgupta156@gmail.com",
			phone: "9113076325",

		},
		{
			id: "000111",
			active: true,
			name: "ABC",
			email: "ranjeetgupta156@gmail.com",
			phone: "9113076325",

		},
		{
			id: "000111",
			active: false,
			name: "ABC",
			email: "ranjeetgupta156@gmail.com",
			phone: "9113076325",

		},
	]

	createRows = rows => {
		return rows.map((prop, key) => {
			return (
				<tr>
					<th scope="row">{prop.name}</th>
					<td>{prop.email}</td>
					<td>{prop.phone}</td>
					<td>{prop.active ?
						<Badge color="" className="badge-dot">
							<i className="bg-success" />
                          active
                        </Badge> :
						<Badge color="" className="badge-dot">
							<i className="bg-danger" />
                          inactive
                        </Badge>}</td>

					<td>{prop.active ?
						<Button color="success">Activate</Button> :
						<Button color="danger">Deactivate</Button>}</td>
					<td className="text-right">
						<Button color="link" to={`/admin/user/${prop.id}`} tag={Link}>
							Edit
              </Button>
					</td>
				</tr>

			);
		});
	};
	render() {
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt-5" fluid>
					{/* Table */}
					<Row>
						<div className="col">
							<Card className="shadow">
								<CardHeader className="border-0 d-flex justify-content-between">
									<h3 className="mb-0">Users</h3>
									<Button color="primary" size="md" to="/admin/add-user" tag={Link}>
										<i className=" ni ni-fat-add" /> Create new Batch
                  </Button>
								</CardHeader>
								<Table className="align-items-center table-flush" responsive>
									<thead className="thead-light">
										<tr>
											<th scope="col">Name</th>
											<th scope="col">Email</th>
											<th scope="col">Phone</th>
											<th scope="col">Status</th>
											<th scope="col" />
											<th scope="col" />
										</tr>
									</thead>
									<tbody>{this.createRows(this.batches)}</tbody>
								</Table>
								<CardFooter className="py-4">
									<nav aria-label="...">
										<Pagination
											className="pagination justify-content-end mb-0"
											listClassName="justify-content-end mb-0"
										>
											<PaginationItem className="disabled">
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
													tabIndex="-1"
												>
													<i className="fas fa-angle-left" />
													<span className="sr-only">Previous</span>
												</PaginationLink>
											</PaginationItem>
											<PaginationItem className="active">
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
												>
													1
                        </PaginationLink>
											</PaginationItem>
											<PaginationItem>
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
												>
													2 <span className="sr-only">(current)</span>
												</PaginationLink>
											</PaginationItem>
											<PaginationItem>
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
												>
													3
                        </PaginationLink>
											</PaginationItem>
											<PaginationItem>
												<PaginationLink
													href="#pablo"
													onClick={e => e.preventDefault()}
												>
													<i className="fas fa-angle-right" />
													<span className="sr-only">Next</span>
												</PaginationLink>
											</PaginationItem>
										</Pagination>
									</nav>
								</CardFooter>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

export default Users;

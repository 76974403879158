import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Avatar from "assets/img/theme/avatar.png";

class UserDetails extends React.Component {
  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt-5" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="pt-3 card-profile-image">
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={Avatar}
                        />
                    </div>
                  </Col>
                </Row>
                <CardBody className="mt-8 pt-6">
                  <div className="text-center">
                    <h3>
                     User Name
                    </h3>
                    <div className="mt-2">
                      username@gmail.com
                    </div>
                    <div>
                     9228586858
                    </div>
                    {/* <hr className="my-4" />
                    <p>
                      lorem
                    </p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">User Details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-name"
                              name="name"
                              placeholder="John Doe"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="name@example.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                             Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              
                              id="input-phone"
                              placeholder="9876543210"
                              type="tel"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label"
                              htmlFor="input-batch">Batch</label>
                            <Input type="select" name="batch" id="input-batch">
                            <option>select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            </Input>
                        </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Payment Details
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                         
                        </Col>
                      </Row>
                     
                    </div>
                   
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default UserDetails;

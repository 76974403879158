import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";

class AddEmployee extends React.Component {
	render() {
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt-5" fluid>
					<Row>
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">Add Employee Details</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-name"
														>
															Name
                            </label>
														<Input
															className="form-control-alternative"
															id="input-name"
															name="name"
															placeholder="name"
															type="text"
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-email"
														>
															Email address
                            </label>
														<Input
															className="form-control-alternative"
															id="input-email"
															name="email"
															placeholder="jesse@example.com"
															type="email"
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-phone"
														>
															Phone
                            </label>
														<Input
															className="form-control-alternative"
															id="input-phone"
															name="phone"
															placeholder="Phone"
															type="tel"
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-department"
														>
															Department
                            </label>
														<Input type="select" name="department" id="input-department">
															<option>select</option>
															<option value="sales">Sales</option>
															<option value="accounts">Accounts</option>
															<option value="hr">Human Resource Management</option>
														</Input>
													</FormGroup>
												</Col>

											</Row>
											<Row>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-adhar"
														>
															Adhar
                            </label>
														<Input
															className="form-control-alternative"
															id="input-adhar"
															name="adhar"
															placeholder="xxxx xxxx xxxx"
															type="text"
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-pan"
														>
															PAN
                            </label>
														<Input
															className="form-control-alternative"
															id="input-pan"
															name="pan"
															placeholder="AAAAAXXXXA"
															type="text"
														/>
													</FormGroup>
												</Col>
											</Row>
										</div>
										<hr className="my-4" />
										{/* Address */}
										<h6 className="heading-small text-muted mb-4">
											Contact information
                    </h6>
										<div className="pl-lg-4">
											<Row>
												<Col md="12">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-address"
														>
															Address
                            </label>
														<Input
															className="form-control-alternative"
															defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
															id="input-address"
															placeholder="Home Address"
															type="text"
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="4">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-city"
														>
															City
                            </label>
														<Input
															className="form-control-alternative"
															defaultValue="New York"
															id="input-city"
															placeholder="City"
															type="text"
														/>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-state"
														>
															State
                            </label>
														<Input
															className="form-control-alternative"
															defaultValue="Bihar"
															id="input-state"
															placeholder="State"
															type="text"
														/>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-zipcode"
														>
															Postal code
                            </label>
														<Input
															className="form-control-alternative"
															id="input-zipcode"
															placeholder="Postal code"
															type="number"
														/>
													</FormGroup>
												</Col>
											</Row>
											<div className="d-flex justify-content-between">
												<Button color="primary"
													onClick={() => { }}
												>
													Submit
                          </Button>

												<Button
													to="/admin/employees"
													tag={Link}
												>
													cancel
                          </Button>
											</div>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default AddEmployee;

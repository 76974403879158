import React from "react";

// reactstrap components
// import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  render() {
    return (
        <div className="header bg-default pt-md-7 shadow-lg"/>
    );
  }
}

export default Header;

import React from "react";

// reactstrap components
import {
	Table,
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

class BatchDetails extends React.Component {
	videos=[
		{
			id:"",
			name:"class 1 : Type of trading",
			link:""
		},
		{
			id:"",
			name:"class 2 : Cash equity market trading",
			link:""
		},
		{
			id:"",
			name:"class 3 : Future and option trading",
			link:""
		},
	]
	createRows = rows => {
		return rows.map((prop, key) => {
			return (
				<tr key={key}>
				<td>{prop.name}</td>
				<td><i className="fa fa-trash text-danger"></i></td>
			</tr>
				);
		});
	};

	render() {
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt-5" fluid>
					<Row>
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">Batch Details</h3>
										</Col>
										<Col className="text-right" xs="4">
											<Button
												color="primary"
												href="#pablo"
												onClick={e => e.preventDefault()}
												size="sm"
											>
												Update
                      </Button>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="12">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-name"
														>
															Name
                            </label>
														<Input
															className="form-control-alternative"
															id="input-name"
															name="name"
															placeholder="name"
															type="text"
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-start-date"
														>
															Start Date
                            </label>
														<Input
															className="form-control-alternative"
															defaultValue={new Date().getDate}
															id="input-start-date"
															name="start_date"
															placeholder=""
															type="date"
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-end-date"
														>
															End Date
                            </label>
														<Input
															className="form-control-alternative"
															defaultValue={new Date().getDate}
															id="input-end-date"
															name="end_date"
															placeholder=""
															type="date"
														/>
													</FormGroup>
												</Col>
											</Row>
											</div>
										<hr className="my-4" />
										{/* Address */}
										<h6 className="heading-small text-muted mb-4">
											Add Videos
                    </h6>
										<div className="pl-lg-4">
											<Row>
												<Col md="12">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-video-name"
														>
															Name
                            </label>
														<Input
															className="form-control-alternative"
															name="address"
															id="input-video-name"
															placeholder="Class 22 : Trend Analysis"
															type="text"
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col md="12">
												<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-video-file"
														>
															Video
                            </label>
														<Input
															className="form-control-alternative"
															name="address"
															id="input-video-file"
															placeholder="Class 22 : Trend Analysis"
															type="file"
														/>
														<small className="text-danger">only .mp4.avi.ogg</small>
													</FormGroup>
												</Col>
											</Row>
											<Button color="primary"
												onClick={()=>{}}
											>
												Upload
											</Button>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
						<Col className="order-xl-1" xl="4">
						<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
											<h3 className="mb-0">Videos Uploaded</h3>
								</CardHeader>
								<CardBody>
								<Table className="align-items-center table-flush" responsive>
									<tbody>{this.createRows(this.videos)}
									</tbody>
								</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default BatchDetails;
